@if (appIsEmbedded$ | ngrxPush) {
  <div class="bg-surface">
    <schaeffler-banner></schaeffler-banner>
    <div class="border-t border-border"></div>
    <router-outlet></router-outlet>
  </div>
} @else {
  <schaeffler-app-shell
    [appTitle]="appTitle"
    [appTitleLink]="'/app'"
    [hasSidebarLeft]="true"
    [footerLinks]="footerLinks"
    [appVersion]="appVersion"
    [footerFixed]="false"
    [scrollToTop]="true"
  >
    <ng-container sidenavBody>
      <div class="px-5">
        <div class="py-4">
          <ga-user-settings></ga-user-settings>
        </div>
      </div>
    </ng-container>
    <ng-container mainContent>
      <div class="flex h-full min-h-full w-full flex-col">
        <div class="h-full w-full">
          <schaeffler-banner></schaeffler-banner>
          <div class="border-t border-border"></div>
          <router-outlet></router-outlet>
        </div>
        <div
          *transloco="let t; read: 'legal'"
          class="mx-auto w-full max-w-2xl bg-surface"
          id="cookie-settings"
          [ngClass]="{ hidden: !isCookiePage }"
        >
          <div class="py-3 px-4">
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">
              {{ t('cookieSettings') }}
            </button>
          </div>
          <div class="py-3 md:px-4">
            <div id="ot-sdk-cookie-policy"></div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngrxLet="partnerVersion$ as partnerVersion"
      headerInlineContent
    >
      @if (partnerVersion) {
        <img
          class="h-4 sm:h-8 md:h-12"
          [src]="getPartnerVersionLogoUrl(partnerVersion)"
        />
      }
    </ng-container>
  </schaeffler-app-shell>
}
